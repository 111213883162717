#app {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: auto;
}

#sidebar {
    margin-left: 5px;
}

#sidebar > div {
    padding: 0.7rem;
}

hr {
    border-color: hsl(0, 0%, 64%);
    border-top: 1px solid;
}