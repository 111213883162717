#color-controls {
    line-height: 2;
}

.radio-wrapper {
    position: relative;
    padding-left: 2rem;
}

.radio-wrapper > [type=radio] {
    position: absolute;
    left: 0;
    top: 0.4rem;
}

.radio-wrapper .react-colorful {
    width: 7.5rem;
    height: 7.5rem;
  }
.radio-wrapper .react-colorful__hue {
    height: 1.25rem;
  }