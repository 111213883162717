#category-picker {
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.category {
  border: 1px solid rgba(0, 0, 0, 0.8); 
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  font-size: 1.5rem;
}

.category-picker-hex {
  font-style: italic;
  font-size: 1rem;
}

.draggable {
  cursor: move;
}