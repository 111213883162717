.chapter {
    text-decoration: underline;
}

.delete-icon {
    margin-left: 2rem;
}

h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-block: 0;
}

h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-block: 0;
}

li {
    margin-block: 1.5rem;
}

p {
    font-size: 0.9rem;
    font-style: italic;
    margin-block: 0.5rem;
}

h3 span {
    cursor: pointer;
}

ul {
    list-style: none;
    padding-left: 0;
}